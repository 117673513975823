import React  from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import './Navbar.css'
import { Component } from 'react'

class Navbar extends Component {
    state = {isHamburgerExpanded : false}

    /** Toggles between expanded and closed hamburger menu */
    handleClick = () => {
        this.setState({isHamburgerExpanded: !this.state.isHamburgerExpanded})
    }
    /** Closes between hamburger menu upon selecting page to navigate to*/
    closeHamburger = () => {
        this.setState({isHamburgerExpanded: false})
    }

    render() {
        return (
            <nav className="nav">
                <Link to="/photos" className="site-title" onClick={this.closeHamburger}>keonook</Link>
                <ul className={this.state.isHamburgerExpanded ? "nav-opened": "nav-closed"}>
                    <CustomLink to="/photos" onClick={this.closeHamburger}>Photos</CustomLink>
                    <CustomLink to="/videos" onClick={this.closeHamburger}>Videos</CustomLink>
                    <CustomLink to="/contact" onClick={this.closeHamburger}>Contact</CustomLink>
                </ul>
                {/** Hamburger menu for mobile devices */}
                <div className='mobile' onClick={this.handleClick}>
                    <i id='bar' className={this.state.isHamburgerExpanded ? "fas fa-times" : "fas fa-bars"}></i>
                </div>
            </nav>
        )
    }
}

const CustomLink = ({to, children, ...props}) => {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({path: resolvedPath.pathname, end: true})
    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to} {...props}>
                {children}
            </Link>
        </li>
    )

}

export default Navbar