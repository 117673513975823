import React  from 'react';
import './App.css';
import Photos from './Photos';
import Navbar from './Navbar';
import Videos from './Videos';
import Contact from './Contact';
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div>
      <Navbar></Navbar>
      <Routes>
        <Route path="/"element={<Photos />} />
        <Route path="/photos"element={<Photos />} />
        <Route path="/videos"element={<Videos />} />
        <Route path="/contact"element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
