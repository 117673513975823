import React  from 'react';
import './Videos.css'
import { InstagramEmbed } from 'react-social-media-embed';


const Videos = () => {
    return (
        <div className='instagram-video'>
            <p>Alo Toronto</p>
            <InstagramEmbed url="https://www.instagram.com/reel/CnKP4c4r2dR/" width={400}/>
        </div>
    )
}

export default Videos