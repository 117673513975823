import React  from 'react';
import './Contact.css'

const Contact = () => {
    return (
        <div className='contact'>
            <img className="profile" src='https://drive.google.com/thumbnail?id=12vCTxhfeHpUEvxXOxP_hbbuVC_Dy7_nC&sz=w1000'/>
            <div className='message' >
                <p>
                    Hey I'm Keon!<br/>
                    <br/>
                    I am a photo & video creator based in Vancouver, BC. <br/>
                    For inquiries regarding bookings, you can reach me on Instagram <a className="ig-hyperlink" href="https://www.instagram.com/keonook">@keonook</a>!<br/>
                    <br/>
                    I'm excited to connect with you!<br/>
                    <br/>
                    Best,<br/>
                    Keon
                </p>
            </div>
        </div>
    )
}

export default Contact