import React, {useState} from "react"
import './Photos.css'

const Photos = () => {

    /** TODO: replace data to retrieve img from database */
    let data = [
        {
            id: 1,
            imgSrc: 'https://drive.google.com/thumbnail?id=1SHDNBOu4ymxlqQGHrKRS-ljmg8qLBQ3j&sz=w1000',
        },
        {
            id: 2,
            imgSrc: 'https://images.squarespace-cdn.com/content/65d4f7d01abe630ba33b1ee4/9b9576b4-aaa3-4c73-a3d9-a3b54d1f78dd/IMG_9324.JPG?content-type=image%2Fjpeg',
        },
        {
            id: 3,
            imgSrc: 'https://images.squarespace-cdn.com/content/65d4f7d01abe630ba33b1ee4/12bc0396-149e-437e-aaa3-41819a473cd6/V2-0002_Untitled00000000.jpg?content-type=image%2Fjpeg',
        },
        {
            id: 4,
            imgSrc: 'https://images.squarespace-cdn.com/content/65d4f7d01abe630ba33b1ee4/639dbe76-8b12-4514-8481-889b8342000a/V1-0007_tor00000000.jpg?content-type=image%2Fjpeg',
        },
        {
            id: 5,
            imgSrc: 'https://images.squarespace-cdn.com/content/v1/65d4f7d01abe630ba33b1ee4/1baf5ebc-3d25-4f4e-a53c-189fce924513/B8E2D402-2579-467E-AB4E-E50419AB0062.jpg',
        },
        {
            id: 6,
            imgSrc: 'https://images.squarespace-cdn.com/content/v1/65d4f7d01abe630ba33b1ee4/329086ff-cf36-4d45-936a-fd373dbffffa/V1-0014_Untitled00000000.jpg',
            
        },
        {
            id: 7,
            imgSrc: 'https://images.squarespace-cdn.com/content/v1/65d4f7d01abe630ba33b1ee4/73da0f4f-01bd-4518-bb47-5a9433e48b6b/V1-0012_Untitled00000000.jpg',
        },
        {
            id: 8,
            imgSrc: 'https://drive.google.com/thumbnail?id=1BAheaDvUXeIXD_75a5Ro9LEfYBuEv2Ub&sz=w1000',
        },
        {
            id: 9,
            imgSrc: 'https://drive.google.com/thumbnail?id=1MVzGGASjTYOeTRqoNJrohPu_IpEdh1HH&sz=w1000',
            
        },
        {
            id: 10,
            imgSrc: 'https://images.squarespace-cdn.com/content/v1/65d4f7d01abe630ba33b1ee4/5988fc54-e989-408a-8f33-24968e8c6885/V1-0004_tor00000000.jpg',
        },
        {
            id: 11,
            imgSrc: 'https://images.squarespace-cdn.com/content/65d4f7d01abe630ba33b1ee4/0ff2a095-1c30-4e39-8984-d5f3df5c7c8c/New+Note.jpeg?content-type=image%2Fjpeg',
        },
        {
            id: 12,
            imgSrc: 'https://drive.google.com/thumbnail?id=1JFPzoKmImH5P19-eSJcIT63cSWRdut7R&sz=w1000',
        },
        {
            id: 13,
            imgSrc: 'https://drive.google.com/thumbnail?id=1xMS7RfoN4NyVPaxx497zGViyBe1hIIKr&sz=w1000',
        },
        {
            id: 14,
            imgSrc: 'https://images.squarespace-cdn.com/content/v1/65d4f7d01abe630ba33b1ee4/1f030dfe-7411-4080-a4cb-cf3e64834c8d/IMG_3334.JPG',
        },
        {
            id: 15,
            imgSrc: 'https://drive.google.com/thumbnail?id=1W0xYg1TIXgls7BjwsMrr5oyg7ETo2maq&sz=w1000',
        },
        {
            id: 16,
            imgSrc: 'https://drive.google.com/thumbnail?id=1s6fAtIU0hsUWiQA6XWskudOKn6ia6qw_&sz=w1000',
        },
        {
            id: 17,
            imgSrc: 'https://drive.google.com/thumbnail?id=1vIHy6LU4ELdeje2yMOOLNWSiM9k7GZIJ&sz=w1000',
        },
        {
            id: 18,
            imgSrc: 'https://drive.google.com/thumbnail?id=1jlmo61gb144tzaaQ9fXjJ2BLbeUTjPrE&sz=w1000',
        },
        {
            id: 19,
            imgSrc: 'https://drive.google.com/thumbnail?id=16MtQLxfYlF-k7cmmGQtThLbn3hNsFqe8&sz=w1000',
        },
        {
            id: 20,
            imgSrc: 'https://drive.google.com/thumbnail?id=1kWGnE2wb5KFq65yHHMslGhjvEvIFN30t&sz=w1000',
        },
        {
            id: 21,
            imgSrc: 'https://drive.google.com/thumbnail?id=1GcM2kc6FCnfCsqvtZuQSvX6KRyO9O2fP&sz=w1000',
        },
        {
            id: 22,
            imgSrc: 'https://drive.google.com/thumbnail?id=1mwSvC5Pd_heMnl0LZhJIBSOcJTY3DMGI&sz=w1000',
        }
    ]
    

    const [model, setModel] = useState(false);
    const [popupSrc, setPopupSrc] = useState('');
    const getImg = (imgSrc) => {
        setPopupSrc(imgSrc)
        setModel(true)
    }

    return (
        <>
        {/** TODO: Enhance pop-up with easy exit/navigation features */}
        <div className={model ? "model open": "model"} onClick={() => {setModel(false)}}>
            <img src={popupSrc}/>
        </div>

        {/** Grid for Photos */}
        <div className="gallery">
            {data.map((item, idx) => {
                return(
                    <div className="photos" key={idx} onClick={() => getImg(item.imgSrc)}>
                        <img src={item.imgSrc} style={{width:'100%'}}/>
                    </div>
                )
            })}
        </div>
        </>
    )
}

export default Photos